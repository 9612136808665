import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import CookieUtils from "../utils/CookieUtils";

function TopBar() {

    const {t, i18n} = useTranslation();

    useEffect(() => {
        CookieUtils.initializeDataStore(i18n);
    }, [i18n]);

    function scrollTo(id) {
        const section = document.getElementById(id);
        section.scrollIntoView({behavior: "smooth", block: "start"});
    }

    const handleLanguageChange = (lng) => {
        CookieUtils.changeLanguage(lng, i18n);
        window.location.reload();
    };

    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
                <h1 className="custom-font-title ms-4 mt-2 mb-2" style={{fontSize: "50px"}}>Robert Baltaru</h1>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNavDarkDropdown" aria-controls="navbarNavDarkDropdown"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse justify-content-end" id="navbarNavDarkDropdown">
                    <ul className="navbar-nav d-flex align-items-center">
                        <li className="nav-item">
                            <a className="nav-link custom-font" onClick={() => scrollTo("passion_id")}
                               style={{cursor: "pointer"}}>
                                {t("top_bar_passion")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <span className="mx-2 d-none d-sm-inline">|</span>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link custom-font" onClick={() => scrollTo("skills_id")}
                               style={{cursor: "pointer"}}>
                                {t("top_bar_skills")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <span className="mx-2 d-none d-sm-inline">|</span>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link custom-font" onClick={() => scrollTo("contact_id")}
                               style={{cursor: "pointer"}}>
                                {t("top_bar_contact")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <span className="mx-2 d-none d-sm-inline">|</span>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle custom-font" href="#"
                               id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown"
                               aria-expanded="false">
                                {(() => {
                                    switch (CookieUtils.getLanguage()) {
                                        case 'ro':
                                            return t("top_bar_language_ro");
                                        case 'es':
                                            return t("top_bar_language_es");
                                        case 'fr':
                                            return t("top_bar_language_fr");
                                        case 'ru':
                                            return t("top_bar_language_ru");
                                        default:
                                            return t("top_bar_language_en");
                                    }
                                })()}
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end"
                                aria-labelledby="navbarDarkDropdownMenuLink">
                                <li><a className="dropdown-item custom-font" style={{cursor: "pointer"}}
                                       onClick={() => handleLanguageChange("ro")}>{t("top_bar_language_ro")}</a></li>
                                <li><a className="dropdown-item custom-font" style={{cursor: "pointer"}}
                                       onClick={() => handleLanguageChange("en")}>{t("top_bar_language_en")}</a></li>
                                <li><a className="dropdown-item custom-font" style={{cursor: "pointer"}}
                                       onClick={() => handleLanguageChange("ru")}>{t("top_bar_language_ru")}</a></li>
                                <li><a className="dropdown-item custom-font" style={{cursor: "pointer"}}
                                       onClick={() => handleLanguageChange("es")}>{t("top_bar_language_es")}</a></li>
                                <li><a className="dropdown-item custom-font" style={{cursor: "pointer"}}
                                       onClick={() => handleLanguageChange("fr")}>{t("top_bar_language_fr")}</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default TopBar;
