import {useEffect, useState} from "react";
import arrow from "../asserts/arrow.png";

function StickyArrow() {
    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    function scrollTo() {
        if (scrolled) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        } else {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth'
            });
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div
            style={{
                position: "fixed",
                bottom: "30px",
                right: "30px",
                transform: scrolled ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.5s ease",
                cursor: "pointer",
            }}
        >
            <img
                src={arrow}
                alt="Sticky Image"
                style={{width: "50px", height: "50px", cursor: "pointer"}}
                onClick={() => scrollTo()}
            />
        </div>
    );
}

export default StickyArrow;
