import Cookies from "js-cookie";

function changeLanguage(lng, i18n) {
    const isCookieAllowed = Cookies.get("isCookieAllowed");

    if (isCookieAllowed === "true") {
        Cookies.set("language", lng);
        i18n.changeLanguage(lng);
        return;
    }

    window.sessionStorage.setItem("language", lng);
}

function getLanguage() {
    const isCookieAllowed = Cookies.get("isCookieAllowed");

    if (isCookieAllowed === "true") {
        return Cookies.get("language");
    }

    return window.sessionStorage.getItem("language");
}

function initializeDataStore(i18n) {
    const isCookieAllowed = Cookies.get("isCookieAllowed");

    if (isCookieAllowed === "true") {
        const lng = Cookies.get("language") || "en";
        Cookies.set("language", lng);
        i18n.changeLanguage(lng);
        return;
    }

    const sessionStorageLng = window.sessionStorage.getItem("language") || "en";
    window.sessionStorage.setItem("language", sessionStorageLng);
}

export default {changeLanguage, initializeDataStore, getLanguage}