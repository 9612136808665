import { useTranslation } from "react-i18next";

function Intro() {
    const [t] = useTranslation();

    return (
        <div className="row d-flex justify-content-center pt-5 pb-5 fade-in-up">
            <div className="col-8 d-flex justify-content-center align-items-center border-start border-3">
                <p className="custom-font">
                    {t("intro_message")}
                </p>
            </div>
        </div>
    );
}

export default Intro;
