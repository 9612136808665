import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import js from "../asserts/junit.png";
import java from "../asserts/java.png";
import springboot from "../asserts/springboot.svg";

function MainSkills() {
    const [t] = useTranslation();
    const skillsRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            {threshold: 0.3}
        );

        if (skillsRef.current) {
            observer.observe(skillsRef.current);
        }

        return () => {
            if (skillsRef.current) {
                observer.unobserve(skillsRef.current);
            }
        };
    }, []);

    return (
        <div
            ref={skillsRef}
            className={`row d-flex justify-content-center pt-5 pb-5 ${isVisible ? "fade-in-up" : "invisible"}`}
        >
            <div className="col-8 pt-5 pb-4 ps-4" id="passion_id">
                <h2 className="custom-font">{t("top_bar_passion")}</h2>
            </div>
            <div className="col-10">
                <div className="row d-flex justify-content-center">
                    <div className="col-12 col-sm-3 p-0 mt-5 mb-5" style={{border: "1px solid #f8f9fa"}}>
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{height: "200px", backgroundColor: "#f8f9fa"}}
                        >
                            <img src={js} alt="JavaScript" style={{maxWidth: "130px", height: "auto"}}/>
                        </div>
                        <h4 className="custom-font m-3 mt-4">{t("main_skills_unit_testing_title")}</h4>
                        <p className="custom-font text-muted p-3">{t("main_skills_unit_testing_message")}</p>
                    </div>
                    <div className="col-12 col-sm-3 p-0" style={{border: "1px solid #e9ecef"}}>
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{height: "220px", backgroundColor: "#e9ecef"}}
                        >
                            <img src={java} alt="Java" style={{maxWidth: "100px", height: "auto"}}/>
                        </div>
                        <h4 className="custom-font m-3 mt-4">Java</h4>
                        <p className="custom-font text-muted p-3">{t("main_skills_java_message")}</p>
                    </div>
                    <div className="col-12 col-sm-3 p-0 mt-5 mb-5" style={{border: "1px solid #f8f9fa"}}>
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{height: "200px", backgroundColor: "#f8f9fa"}}
                        >
                            <img src={springboot} alt="SpringBoot" style={{maxWidth: "100px", height: "auto"}}/>
                        </div>
                        <h4 className="custom-font m-3 mt-4">Spring boot</h4>
                        <p className="custom-font text-muted p-3">{t("main_skills_spring_boot_message")}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainSkills;
