import axios from 'axios';

class AxiosService {
    constructor() {
        this.instance = axios.create({
            baseURL: 'https://robertbaltaru-api.netlify.app/.netlify/functions/app',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async get(endpoint, params = {}) {
        try {
            return await this.instance.get(endpoint, {params});
        } catch (error) {
            console.error('GET request failed', error);
            return null;
        }
    }

    async post(endpoint, data = {}) {
        try {
            return await this.instance.post(endpoint, data);
        } catch (error) {
            console.error('POST request failed', error);
            return null;
        }
    }

    async put(endpoint, data = {}) {
        try {
            return await this.instance.put(endpoint, data);
        } catch (error) {
            console.error('PUT request failed', error);
            return null;
        }
    }

    async delete(endpoint) {
        try {
            return await this.instance.delete(endpoint);
        } catch (error) {
            console.error('DELETE request failed', error);
            return null;
        }
    }
}

export default AxiosService;
