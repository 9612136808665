import {useEffect, useState} from "react";

export const FailAlert = ({message, isDisabled, setIsDisabled}) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (!isDisabled) {
            setIsVisible(true);
            const timer = setTimeout(() => {
                setIsDisabled(true);
            }, 3000);

            return () => clearTimeout(timer);
        } else if (isDisabled && isVisible) {
            const timer = setTimeout(() => {
                setIsVisible(false);
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [isDisabled, setIsDisabled, isVisible]);

    if (!isVisible) return null;

    return (
        <div
            className={`alert alert-danger text-center ${!isDisabled ? "slide-in" : "slide-out"}`}
            style={{
                width: "auto",
                position: "fixed",
                bottom: "20px",
                right: "20px",
                margin: "0 auto",
                zIndex: 9999,
                paddingRight: "55px",
                paddingLeft: "25px",
            }}
            role="alert"
        >
            {message}
            <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => setIsDisabled(true)}
                style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)"
                }}
            ></button>
            {!isDisabled && (
                <div
                    style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        height: "4px",
                        backgroundColor: "#f08080",
                        animation: "progress-bar-animation 4s linear",
                    }}
                />
            )}
            <style>{`
                @keyframes progress-bar-animation {
                    from { width: 0%; }
                    to { width: 100%; }
                }

                @keyframes slideIn {
                    from { transform: translateX(100%); }
                    to { transform: translateX(0); }
                }

                @keyframes slideOut {
                    from { transform: translateX(0); }
                    to { transform: translateX(100%); }
                }

                .slide-in {
                    animation: slideIn 0.5s forwards;
                }

                .slide-out {
                    animation: slideOut 0.5s forwards;
                }
            `}</style>
        </div>
    );
};

export const SuccessAlert = ({message, isDisabled, setIsDisabled}) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (!isDisabled) {
            setIsVisible(true);
            const timer = setTimeout(() => {
                setIsDisabled(true);
            }, 6000);

            return () => clearTimeout(timer);
        } else if (isDisabled && isVisible) {
            const timer = setTimeout(() => {
                setIsVisible(false);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [isDisabled, setIsDisabled, isVisible]);

    if (!isVisible) return null;

    return (
        <div
            className={`alert alert-success text-center ${!isDisabled ? "slide-in" : "slide-out"}`}
            style={{
                width: "auto",
                position: "fixed",
                bottom: "20px",
                right: "20px",
                margin: "0 auto",
                zIndex: 9999,
                paddingRight: "55px",
                paddingLeft: "25px",
            }}
            role="alert"
        >
            {message}
            <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => setIsDisabled(true)}
                style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)"
                }}
            ></button>
            {!isDisabled && (
                <div
                    style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        height: "4px",
                        backgroundColor: "#5bbd5b",
                        animation: "progress-bar-animation 6s linear",
                    }}
                />
            )}
            <style>{`
                @keyframes progress-bar-animation {
                    from { width: 0%; }
                    to { width: 100%; }
                }

                @keyframes slideIn {
                    from { transform: translateX(100%); }
                    to { transform: translateX(0); }
                }

                @keyframes slideOut {
                    from { transform: translateX(0); }
                    to { transform: translateX(100%); }
                }

                .slide-in {
                    animation: slideIn 0.5s forwards;
                }

                .slide-out {
                    animation: slideOut 0.5s forwards;
                }
            `}</style>
        </div>
    );
};