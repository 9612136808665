import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import ro from '../locales/ro.json';
import en from '../locales/en.json';
import ru from '../locales/ru.json';
import es from '../locales/es.json';
import fr from '../locales/fr.json';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {translation: en},
            ro: {translation: ro},
            ru: {translation: ru},
            es: {translation: es},
            fr: {translation: fr},
        },
        lng: 'en', // default language
        fallbackLng: 'ro',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;